import React from "react";

import type { HeadFC } from "gatsby";
import { graphql } from "gatsby";

import { Box } from "@mui/system";

import Link from "@mui/material/Link";

import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Typography from "@mui/material/Typography";

import { Layout, BaseHead } from "../components";

const ContactPage = ({
  data: {
    markdownRemark: { html },
  },
}: any) => {
  return (
    <Layout>
      <main>
        <Box
          sx={{ textAlign: "center", fontSize: 18 }}
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ContactPhoneIcon />
            <Typography margin={1} variant="h6">
              <Link href="tel:+3396203040" color="inherit">
                02 96 20 30 40
              </Link>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ContactMailIcon />
            <Typography margin={1} variant="h6">
              <Link href="mailto:joel.babey@worldonline.fr" color="inherit">
                joel.babey@worldonline.fr
              </Link>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <LocationOnIcon />
            <Typography margin={1} variant="h6">
              Le Grand Etang, 11 Route du Palus - Plouha 22580 - France
            </Typography>
          </Box>
        </Box>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query legalsQuery {
    markdownRemark(frontmatter: { key: { eq: "contact" } }) {
      html
    }
  }
`;

export const Head: HeadFC = () => <BaseHead title="Nous contacter" />;

export default ContactPage;
